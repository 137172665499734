export const TYPECOPY = {
	COPY_FOR: 1,
	COPY_TO: 2,
};

export const BLOCK_TYPE = {
	PAPO_INTERNO: 43,
	PAPO_EXTERNO: 44,
	VIDEO_DA_SEMANA: 45,
};
