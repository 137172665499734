import { Button } from '@/components/componentsCN/ui/button';
import { Input } from '@/components/componentsCN/ui/input';
import { Label } from '@/components/componentsCN/ui/label';
import AnimatedPage from '@/pages/AnimationPage';
import { BounceContainerVariants, BounceVariants, loadingContainer, loadingDot, loadingLetter } from '@/utils/constants/framer/animations';
import { Checkbox, Image } from '@nextui-org/react';
import { motion } from 'framer-motion';
import { LucideEye, LucideEyeOff } from 'lucide-react';
import { FormEvent, Suspense, useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/image/LOGO_PETIM_2.0.svg';
import AuthContext from '../../contexts/AuthContext';
import LoadingPage from '../Loading';

export default function SignIn() {
	const { signIn } = useContext(AuthContext);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [remember, setRemember] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const local = useLocation().pathname;

	async function handleSubmit(e: FormEvent) {
		e.preventDefault();
		setLoading(true);
		let dataForm = {
			mail: email,
			password: password,
			remember: remember,
		};

		const response = await signIn(dataForm);
		if (response)
			if (local != '/' && local != '/login') {
				navigate(local);
			} else navigate(`/`);
		setLoading(false);
	}

	const cookieSign = () => {
		if (!remember) {
			setRemember(true);
		} else {
			setRemember(false);
		}
	};

	const [isVisible, setIsVisible] = useState(false);

	function alterVisibility(event: any) {
		event.preventDefault();

		var input = document.querySelector('#password');

		if (input?.getAttribute('type') === 'password') {
			setIsVisible(true);
			input.setAttribute('type', 'text');
		} else {
			setIsVisible(false);
			input?.setAttribute('type', 'password');
		}
	}

	return (
		<Suspense fallback={<LoadingPage />}>
			<AnimatedPage>
				<div className="flex h-screen w-full flex-col items-center justify-center bg-background transition-all duration-150 lg:flex-row lg:gap-8 lg:p-8">
					<div className="items-center justify-center px-8 lg:flex-1 lg:p-4">
						<Image src={Logo} alt="Logo" width={4098} className="rounded-none" />
					</div>
					<div className="flex w-[80%] items-center justify-center lg:flex-1 ">
						<form onSubmit={handleSubmit} className=" w-full flex-col gap-4 text-center text-white lg:w-[80%] lg:p-4">
							<Label htmlFor="email" className="mt-4 flex w-full flex-col gap-2 text-start font-medium sm:gap-1 md:text-sm lg:text-base">
								Digite seu usuário
								<Input
									id="email"
									className="h-12 border-primary font-normal focus:border-none"
									autoComplete="off"
									type="text"
									name="emailarea"
									placeholder="Digite seu usuário"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</Label>
							<Label
								htmlFor="password"
								className="relative mt-4 flex w-full flex-col gap-2 text-start font-medium sm:gap-1 md:text-sm lg:text-base"
							>
								Senha
								<div className="relative flex items-center ">
									<Input
										id="password"
										className="h-12 flex-1 border-primary font-normal focus:border-none"
										type="password"
										name="passwordarea"
										placeholder="Digite sua senha"
										maxLength={25}
										size={25}
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									<Button
										type="button"
										variant={'ghost'}
										onClick={alterVisibility}
										className="absolute right-2 flex items-center justify-center"
									>
										<LucideEyeOff data-hide={isVisible} className="data-[hide=true]:hidden" size={14} />
										<LucideEye data-hide={!isVisible} className="data-[hide=true]:hidden" size={14} />
									</Button>
								</div>
							</Label>
							<div className="my-4 flex w-full justify-start">
								<Checkbox onClick={cookieSign} id="checkbox" classNames={{ label: ' font-normal', base: 'text-xs' }}>
									Manter-me Conectado
								</Checkbox>
							</div>
							{loading ? (
								<LoadingLetters />
							) : (
								<Button type="submit" variant={'primary'} className=" flex h-12 w-full items-center justify-center">
									Acessar
								</Button>
							)}
							<div className="mt-12 text-white">
								<Link
									to="http://ti.pormade.com.br:8080/ords/r/tecinfo/suporte-petim/home?session=15546297356718"
									target="_blank"
									className="botaoNav text-sm font-normal text-opacity-80"
								>
									Suporte
								</Link>
							</div>
						</form>
					</div>
				</div>
			</AnimatedPage>
		</Suspense>
	);
}

export function LoadingLetters() {
	return (
		<motion.div
			style={loadingContainer}
			variants={BounceContainerVariants}
			transition={{ staggerChildren: 0.1 }}
			initial="start"
			animate="end"
			className="flex justify-center"
		>
			<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
				C
			</motion.span>
			<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
				a
			</motion.span>
			<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
				r
			</motion.span>
			<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
				r
			</motion.span>
			<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
				e
			</motion.span>
			<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
				g
			</motion.span>
			<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
				a
			</motion.span>
			<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
				n
			</motion.span>
			<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
				d
			</motion.span>
			<motion.span style={loadingLetter} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}>
				o
			</motion.span>
			<motion.span style={loadingDot} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}></motion.span>
			<motion.span style={loadingDot} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}></motion.span>
			<motion.span style={loadingDot} variants={BounceVariants} transition={{ duration: 0.4, yoyo: Infinity, ease: 'easeInOut' }}></motion.span>
		</motion.div>
	);
}
