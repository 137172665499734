import { Suspense, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';
import LoadingPage from '../pages/Loading';
import SignIn from '../pages/SignIn';

export const PrivateRoute = () => {
	const { signed, loading } = useContext(AuthContext);

	if (loading) return <LoadingPage />;

	return signed ? (
		<>
			<Suspense fallback={<LoadingPage />}>
				<Outlet />
			</Suspense>
		</>
	) : (
		<SignIn />
	);
};
