import Buscador from '../../../Buscador';
import Titulo from '../../../Titulo';

interface HeaderPermissionProps {
	title: string;
	value: string;
	onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

export function HeaderPermission(props: HeaderPermissionProps) {
	return (
		<div className="flex flex-col items-center pb-8 pt-24 text-center lg:flex-row lg:justify-between">
			<Titulo name={props.title} id="title" />
			<Buscador className="mt-3 lg:m-0" value={props.value} onChange={props.onChange} />
		</div>
	);
}
