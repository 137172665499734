import { SwalAlert, SwalConfirmed } from '@/components/Swal';
import { Button } from '@/components/componentsCN/ui/button';
import removeAccents from '@/functions/remove-accents';
import { ToUpperCaseText } from '@/hooks/useReplaceName';
import { api } from '@/services/Api.ts';
import { Checkbox, Divider, cn } from '@nextui-org/react';
import { LucideChevronDown } from 'lucide-react';
import { useContext, useDeferredValue, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { twMerge } from 'tailwind-merge';
import { StructurePageAdmin } from '../../../../components/pages/StructurePageAdmin';
import { HeaderPermission } from '../../../../components/pages/admin/permissions/Header';
import { TYPECOPY } from '../../../../constants/pages/permission/copyContext';
import { IdContextPermission } from '../../../../contexts/pages/Permissions/idCopyContext';
import { dataGet } from '../../../../hooks/axios';
import { IPermissions, Permission } from '../../../../mocks/PermissionSection';
import { PermissionType } from '../../../../types/TypeUser';

export function PermissionByUser() {
	const navigate = useNavigate();
	const params = useParams();

	const [searchParams, setSearchParams] = useSearchParams();
	const search = useDeferredValue(searchParams.get('search') ?? '');

	const [permissions, setPermissions] = useState<IPermissions[]>(Permission);
	const [initialPermissions, setInitialPermissions] = useState<any>();

	const { setUserContext } = useContext(IdContextPermission);
	const { data: user } = dataGet<PermissionType>(`/pessoa/${params.id}`);

	useEffect(() => {
		GetPermissionsByUser();
	}, [user]);

	function GetPermissionsByUser() {
		if (!user) return;
		const newPermissions: IPermissions[] = permissions.map((group) => ({
			...group,
			permissions: group.permissions.map((permission) => {
				const hasPermission = checkIfHasPermission(permission.value);
				if (hasPermission) {
					return { ...permission, checked: !permission.checked };
				}
				return permission;
			}),
		}));
		setPermissions(newPermissions);
		setInitialPermissions(cloneDeep(newPermissions));
	}

	function cloneDeep(obj: {}) {
		return JSON.parse(JSON.stringify(obj));
	}

	function CancelModifyPermissionsByUser() {
		setPermissions(cloneDeep(initialPermissions));
	}

	function ChangeSearch(e: React.ChangeEvent<HTMLInputElement>) {
		setSearchParams({ search: e.target.value });
	}

	function navigateToCopy(type: 'COPY_FOR' | 'COPY_TO') {
		setUserContext({
			id: user?.id ?? null,
			nome: user?.nome ?? '',
			type: TYPECOPY[type],
		});

		navigate({ pathname: '/admin/permissoes/usuarios' });
	}

	function PatchPermission() {
		const permission: number[] = [];
		permissions.flatMap((group) => group.permissions.filter((item) => (item.checked == true ? permission.push(item.value) : null)));

		SwalAlert({
			title: `Prover ${permission?.length} permissões para: ${user?.nome}`,
		}).then((res) => {
			if (res.isConfirmed) {
				api.patch(`/pessoa/atualizarpermissao/${user?.id}`, {
					permissoes: permission,
				})
					.then(() => {
						SwalConfirmed({ title: 'Registrado com sucesso' }).then((res) => {
							res.isConfirmed ? navigate(-1) : null;
						});
					})
					.catch((err) => {
						toast.error(err.response.data.message, {
							position: 'top-right',
						});
					});
			}
		});
	}

	function toggleGroupOfPermissions(groupId: number) {
		let newGroupPermissions = permissions.map((group) => {
			if (group.id === groupId) {
				group.open = !group.open;
			}
			return group;
		});
		setPermissions(newGroupPermissions);
	}

	function onChangePermissionState(permissionId: number) {
		const newPermissions: IPermissions[] = permissions.map((group) => ({
			...group,
			permissions: group.permissions.map((permission) => {
				if (permission.value === permissionId) {
					return { ...permission, checked: !permission.checked };
				}
				return permission;
			}),
		}));

		setPermissions(newPermissions);
	}

	function checkIfHasPermission(permissionId: number) {
		if (!user?.permissoes) return false;
		const hasPermission = user.permissoes.some((permission) => Number(permission.id) === permissionId);

		return hasPermission;
	}

	function filterGroups(str: string) {
		return removeAccents(str.toLowerCase()).includes(removeAccents(search.toLowerCase()));
	}

	function renderFiltredPermissions() {
		const filteredGroups = permissions && permissions.filter((group) => filterGroups(group.name));

		return filteredGroups.map((group, index) => {
			return (
				<div className="w-full" key={index}>
					<div
						onClick={() => toggleGroupOfPermissions(group.id)}
						data-should-hide={!group.name}
						className="flex items-center justify-start gap-2 px-4 py-4 font-normal data-[should-hide=true]:hidden "
					>
						<h1 className="cursor-pointer text-lg font-semibold">{group.name}</h1>
						<LucideChevronDown
							data-is-open={group.open}
							className="cursor-pointer transition-all duration-200 data-[is-open=false]:-rotate-90"
							size={14}
						/>
						<Divider className="flex-1 bg-muted" />
					</div>
					<div className={twMerge(group.open ? 'visible animate-fade-in-from-top ' : 'hidden animate-fade-out-from-bottom', `visible`)}>
						{group?.permissions?.map((permission, indexPermission) => {
							return (
								<div className={`my-4 w-full`} key={indexPermission}>
									<Check
										title={permission.name}
										checked={permission.checked}
										changeValue={() => {
											onChangePermissionState(permission.value);
										}}
									/>
								</div>
							);
						})}
					</div>
				</div>
			);
		});
	}

	return (
		<StructurePageAdmin>
			<HeaderPermission title={user?.nome.toUpperCase() ?? ''} value={search} onChange={ChangeSearch} />
			<div className="flex w-full justify-between">
				<Button variant="outline_primary" onClick={() => navigateToCopy('COPY_TO')}>
					Copiar de:
				</Button>
				<Button variant="outline_primary" onClick={() => navigateToCopy('COPY_FOR')}>
					Copiar para:
				</Button>
			</div>
			<div className="mt-10">{renderFiltredPermissions()}</div>
			<div className="flex justify-between">
				<Button variant="primary" className="mb-4 mr-1 h-10 w-[50%]" onClick={PatchPermission}>
					Salvar
				</Button>
				<Button variant="destructive" className=" mb-6 ml-1 h-10 w-[50%]" onClick={() => CancelModifyPermissionsByUser()}>
					Cancelar
				</Button>
			</div>
		</StructurePageAdmin>
	);
}

interface CheckComponent {
	title: string;
	checked?: boolean;
	changeValue: (isSelected: boolean) => void;
}

function Check({ title, checked, changeValue }: CheckComponent) {
	return (
		<Checkbox
			classNames={{
				base: cn(
					'inline-flex w-full items-center justify-start cursor-pointer rounded-lg border-2 data-[selected=true]:border-primary rounded-xl border-green  bg-transparent border border-muted-foreground/50 hover:border-muted-foreground',
				),
				label: 'w-full max-w-full',
			}}
			className="bg-neutral-300 m-0 flex w-full max-w-full "
			isSelected={checked}
			onValueChange={changeValue}
		>
			<div className="flex w-full justify-between gap-2">
				<div className="text-md flex flex-col items-end gap-1 font-medium">
					<h1>{ToUpperCaseText(title)}</h1>
				</div>
			</div>
		</Checkbox>
	);
}
