import { LoginMessage, LoginMessageSimple } from '@/functions/react-query/get/login-messages/get-all-messages';
import { ToastTypes } from '@/pages/Admin/LoginMessages';
import jwt from 'jwt-decode';
import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PiHandWaving } from 'react-icons/pi';
import { toast, ToastT } from 'sonner';
import Cookies from 'universal-cookie';
import { api } from '../services/Api';
import { CurrentUser, TokenUser } from '../types/TypeUser';

interface Login {
	mail: string;
	password: string;
	remember: boolean;
}

interface AuthContextData {
	signed: boolean;
	user: CurrentUser | null;
	setUser: Dispatch<SetStateAction<CurrentUser | null>>;
	loading: boolean;
	signIn({ mail, password, remember }: Login): Promise<boolean>;
	signOut(): void;
}

type LoginMessageResponse = LoginMessage | LoginMessageSimple;

const AuthContext = createContext<AuthContextData>({} as AuthContextData);
export default AuthContext;

export const AuthProvider = ({ children }: any) => {
	const cookies = new Cookies();
	const [user, setUser] = useState<CurrentUser | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		async function LocalStorageToken() {
			if (!!localStorage.getItem('token')) localStorage.removeItem('token');
			if (!sessionStorage.getItem('logado') && cookies.get('mantenha-conectado') != 'true') {
				cookies.remove('token');
			}
			const token = await cookies.get('token');
			if (!!token) {
				api.defaults.headers.common.Authorization = `Bearer ${token}`;

				await api
					.post('/refresh')
					.then(async (response) => {
						setToken(response.data.access_token);
						setLoading(false);
						sessionStorage.setItem('logado', 'true');
					})
					.catch(() => {
						setLoading(false);
					});
			} else if (!!token == false) setLoading(false);
		}
		LocalStorageToken();
	}, []);

	function setToken(token: string): void {
		api.defaults.headers.common.Authorization = `Bearer ${token}`;
		let decriptToken: TokenUser = jwt(token);
		let newUserToken = {} as CurrentUser;
		newUserToken.id = decriptToken.sub;
		newUserToken.cpf = decriptToken.cpf;
		newUserToken.nome = decriptToken.nome;
		newUserToken.email = decriptToken.email;
		newUserToken.cracha = decriptToken.cracha;
		newUserToken.emailVerificado = decriptToken.emailVerificado;
		newUserToken.permissao = [];
		decriptToken.permissoes.map((permissao) => {
			newUserToken.permissao.push(+permissao);
		});
		cookies.set('token', token, { sameSite: 'strict', secure: import.meta.env.PROD, maxAge: timeDifference(decriptToken) });
		setUser(newUserToken);
	}

	const timeDifference = (decode: TokenUser) => decode.exp - Math.floor(new Date().getTime() / 1000);

	async function signIn({ mail, password, remember }: Login) {
		let response: boolean = false;
		let data = {
			email: mail,
			password: password,
			remember: remember,
		};
		api.defaults.headers.common.Authorization = '';
		await api
			.post('/login', data)
			.then(async (userResponse) => {
				if (remember == true) {
					cookies.set('mantenha-conectado', true, {
						maxAge: 34560000,
						path: '/',
					});
				}
				setToken(userResponse.data.access_token);

				sessionStorage.setItem('logado', 'true');

				await registerServiceWorker();
				await showLoginMessage();

				setLoading(false);
				response = true;
			})
			.catch((error) => {
				toast.error(error.response.data.message);
			});
		return response;
	}

	async function showLoginMessage() {
		return await api
			.get<LoginMessageResponse>(`/login-message`)
			.then((res) => {
				const toastConfig: Partial<ToastT> = {
					icon: res.data.mostrar_icone ? <PiHandWaving size={22} className="animate-handShake" /> : <></>,
					style: { color: 'white' },
					...res.data.config_toast,
				};

				return renderToastByType(res.data.config_toast.type, res.data.mensagem, toastConfig);
			})
			.catch((err) => toast.error(err.response.data.message));
	}

	function renderToastByType(toastType: ToastTypes | undefined, toast_message: string, toast_config: Partial<ToastT>) {
		if (toastType === 'success') {
			return toast.success(toast_message, toast_config);
		} else if (toastType === 'error') {
			return toast.error(toast_message, toast_config);
		} else if (toastType === 'warning') {
			return toast.warning(toast_message, toast_config);
		} else if (toastType === 'info') {
			return toast.info(toast_message, toast_config);
		} else return toast.success(toast_message, toast_config);
	}

	async function registerServiceWorker() {
		if (!navigator.serviceWorker) return toast.warning('Não foi possível conectar ao servidor de notificações.');
		return await navigator.serviceWorker
			.register('service-worker.js', {
				scope: './',
			})
			.then(async (serviceWorker) => {
				let subscription = await askNotificationPermission(serviceWorker);

				if (!subscription) {
					const publicKeyResponse = await api.get('/notifications/public_key');

					subscription = await serviceWorker.pushManager.subscribe({
						applicationServerKey: publicKeyResponse.data.public_key,
						userVisibleOnly: true,
					});
				}

				return await api.post('/notifications/register', subscription).catch((err) => toast.error(err.response.data.message));
			})
			.catch((error) => {
				console.error('Service Worker registration failed:', error);
			});
	}

	async function askNotificationPermission(serviceWorker: ServiceWorkerRegistration) {
		return await serviceWorker.pushManager.getSubscription();
	}

	function signOut() {
		setUser(null);
		cookies.remove('token');
		sessionStorage.removeItem('logado');
		cookies.remove('mantenha-conectado');
	}

	return (
		<div>
			<AuthContext.Provider value={{ signed: !!user, user, signIn, signOut, loading: loading, setUser: setUser }}>{children}</AuthContext.Provider>
		</div>
	);
};
