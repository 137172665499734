export default function Titulo({ children, name }: any) {
	return (
		<>
			<SectionTitle />
			<div className={`text-xl font-semibold text-white xsm:text-2xl lg:text-2xl 2xl:text-4xl`}>
				{children}
				<span className="font-semibold">{name}</span>
			</div>
		</>
	);
}

export function Subtitulo({ children, name, envios }: any) {
	return (
		<div className={`my-3 flex items-center justify-between text-base`}>
			{children}
			<label className="font-semibold text-gray">
				{name} : <span className="font-normal text-white opacity-80">{envios}</span>
			</label>
		</div>
	);
}

export function SectionTitle() {
	return <section id="title" className="absolute top-0"></section>;
}
