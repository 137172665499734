import { BLOCK_TYPE } from '@/constants/pages/permission/copyContext';
import { dataGet } from '@/hooks/axios.ts';
import { NotPermission } from '@/pages/Admin/NotPermission/NotPermission';
import LoadingPage from '@/pages/Loading';
import { Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { CurrentUser } from '../types/TypeUser';

export const VerifyUnityColaborator = () => {
	const { data: user, isLoading } = dataGet<CurrentUser>('/usuario/dados');

	const [showPapoDirecao, setShowPapoDirecao] = useState(false);
	useEffect(() => {
		if (!!user?.bloqueado.find((item) => item.tipoId == BLOCK_TYPE.PAPO_INTERNO || item.tipoId == BLOCK_TYPE.PAPO_EXTERNO)) {
			return;
		}
		if (user?.idUnidade == 1 || user?.idUnidade == 3 || user?.tipo === 'EXTERNO') {
			setShowPapoDirecao(true);
		}
		return;
	});

	if (isLoading) return <LoadingPage />;

	return user && showPapoDirecao ? (
		<Suspense fallback={<LoadingPage />}>
			<Outlet />
		</Suspense>
	) : (
		<NotPermission />
	);
};
