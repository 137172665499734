export const loadingContainer = {
	color: 'white',
	display: 'flex',
	alignItems: 'flex-end',
};

export const loadingDot = {
	backgroundColor: 'white',
	width: '.5rem',
	height: '.5rem',
	borderRadius: '50%',
	marginRight: '3px',
	marginBottom: '0.6rem',
};

export const loadingLetter = {
	display: 'block',
};

export const BounceContainerVariants = {
	start: {
		transition: { staggerChildren: 0.1 },
	},
	end: {
		transition: { staggerChildren: 0.1 },
	},
};

export const BounceVariants = {
	start: { opacity: 0, y: 50 },
	end: { opacity: 1, y: 0 },
};

export const dropInList = {
	hidden: {
		y: '-25px',
		opacity: 0,
	},
	visible: {
		y: 0,
		opacity: 1,
		transition: {
			duration: 0.1,
			type: 'spring',
			damping: 25,
			stiffness: 500,
		},
	},
	exit: {
		y: '-25px',
		opacity: 0,
	},
};

export const dropIn = {
	hidden: {
		y: '-100vh',
		opacity: 0,
	},
	visible: {
		y: 0,
		opacity: 1,
		transition: {
			duration: 0.1,
			type: 'spring',
			damping: 25,
			stiffness: 500,
		},
	},
	exit: {
		y: '100vh',
		opacity: 0,
	},
};

export const SlideInLeft = {
	hidden: {
		x: '-350px',
		opacity: 0,
	},
	visible: {
		x: 0,
		opacity: 1,
		transition: {
			duration: 0.6,
			type: 'spring',
			damping: 25,
			stiffness: 500,
		},
	},
	exit: {
		x: '-350px',
		opacity: 0,
	},
};

export const SlideInLeftSlideOutRight = {
	hidden: {
		x: '-350px',
		opacity: 0,
	},
	visible: {
		x: 0,
		opacity: 1,
		transition: {
			duration: 0.6,
			type: 'spring',
			damping: 25,
			stiffness: 500,
		},
	},
	exit: {
		x: '350px',
		opacity: 0,
		transition: {
			duration: 0.6,
		},
	},
};

export const SlideInRight = {
	hidden: {
		x: '350px',
		opacity: 0,
	},
	visible: {
		x: 0,
		opacity: 1,
		transition: {
			duration: 0.6,
			type: 'spring',
			damping: 25,
			stiffness: 500,
		},
	},
	exit: {
		x: '350px',
		opacity: 0,
	},
};

export const SlideInBottom = {
	hidden: {
		y: '350px',
		opacity: 0,
	},
	visible: {
		y: 0,
		opacity: 1,
		transition: {
			duration: 0.6,
			type: 'spring',
			damping: 25,
			stiffness: 500,
		},
	},
	exit: {
		y: '350px',
		opacity: 0,
	},
};

export const SlideInTop = {
	hidden: {
		y: '-350px',
		opacity: 0,
	},
	visible: {
		y: 0,
		opacity: 1,
		transition: {
			duration: 0.6,
			type: 'spring',
			damping: 25,
			stiffness: 500,
		},
	},
	exit: {
		y: '-350px',
		opacity: 0,
	},
};

export const animationItems = {
	initial: { opacity: 0, scale: 0 },
	animate: { opacity: 1, scale: 1 },
	exit: { opacity: 0, scale: 0 },
};

export const container = {
	hidden: { opacity: 1, scale: 0 },
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			delayChildren: 0.3,
			staggerChildren: 0.2,
		},
	},
};

export const itemVariant = {
	hidden: { x: -140, opacity: 0 },
	visible: {
		x: 0,
		opacity: 1,
	},
};

export const slideFromTop = {
	variants: {
		enter: {
			y: 0,
			opacity: 1,
			transition: {
				duration: 0.3,
				ease: 'easeOut',
			},
		},
		exit: {
			y: -200,
			opacity: 0,
			transition: {
				duration: 0.2,
				ease: 'easeIn',
			},
		},
	},
};

export const slideFromBottom = {
	variants: {
		enter: {
			y: 0,
			opacity: 1,
			transition: {
				duration: 0.3,
				ease: 'easeOut',
			},
		},
		exit: {
			y: 200,
			opacity: 0,
			transition: {
				duration: 0.2,
				ease: 'easeIn',
			},
		},
	},
};
