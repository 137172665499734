import { cn } from '@/utils/cn';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const buttonVariants = cva(
	'inline-flex group items-center h-9 gap-2 justify-center whitespace-nowrap rounded-md text-sm font-medium transition-all duration-200 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
	{
		variants: {
			variant: {
				default: 'bg-primary text-primary-foreground shadow hover:bg-primary/90',
				destructive: 'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/60',
				outline: 'border border-muted-foreground/60 bg-transparent text-white shadow-sm hover:bg-muted hover:text-accent-foreground outline-none ring-0',
				outline_primary:
					'border text-primary hover:text-white border-primary bg-transparent hover:bg-primary-foreground hover:border-primary-foreground',
				outline_orange: 'border text-orange hover:text-white border-orange bg-transparent hover:bg-orange/80 hover:border-orange/80',
				dashboard: 'border text-white border-primary/40 bg-primary/30 hover:bg-primary-foreground hover:border-primary-foreground',
				analyse: 'bg-yellow/60 hover:bg-yellow/30 text-white',
				detail: 'bg-black/40 hover:bg-black/30 text-white',
				export: 'border border-blue/60 hover:bg-blue/60 bg-blue/40 text-white',
				primary: 'bg-primary/60 hover:bg-primary/30 shadow-sm text-white',
				secondary: 'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
				ghost: 'hover:bg-accent hover:text-accent-foreground',
				ghost_border: 'bg-transparent border border-muted-foreground/50 hover:border-muted-foreground',
				consider: 'bg-consider/60  hover:bg-consider/30 text-white',
				link: 'text-primary botaoNav',
				day: 'hover:border border-primary hover:rounded-md',
				day_range_start: 'bg-primary text-white rounded-r-none',
				day_range_end: 'bg-primary text-white rounded-l-none',
				day_range_middle: 'bg-muted-foreground/20 hover:bg-muted rounded-none',
				day_outside: 'text-muted-foreground',
				day_disabled: 'cursor-disabled',
			},
			size: {
				default: 'py-1 px-2',
				sm: 'h-8 rounded-md px-3 text-xs',
				lg: 'h-10 rounded-md px-8',
				icon: 'h-9 w-9',
				full: 'w-full',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	},
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
	asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant = 'primary', size, asChild = false, ...props }, ref) => {
	const Comp = asChild ? Slot : 'button';
	return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
Button.displayName = 'Button';

export { Button, buttonVariants };
