import { Button } from '@/components/componentsCN/ui/button';
import { ToUpperCaseText } from '@/hooks/useReplaceName';
import { LucideChevronRight } from 'lucide-react';

interface CardPermissionProps {
	nome: string;
	isCopy?: boolean;
	isCopied?: boolean;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	onClickCopy?: React.MouseEventHandler<HTMLDivElement>;
}

export function CardPermissionListener({ nome, isCopied, isCopy, onClick, onClickCopy }: CardPermissionProps) {
	return (
		<Button variant={'dashboard'} className="flex w-full items-center justify-between px-4 py-8" onClick={onClick}>
			<h1 className="line-clamp-1 text-left">{ToUpperCaseText(nome)}</h1>
			{isCopy ? (
				<div className="font-medium" onClick={onClickCopy}>
					{isCopied ? 'Copiado' : 'Copiar'}
				</div>
			) : (
				<div className="">
					<LucideChevronRight size={30} className="my-auto flex" />
				</div>
			)}
		</Button>
	);
}
