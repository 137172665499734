import { LucideSearch } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

export default function Buscador(props: any) {
	return (
		<div
			className={twMerge(
				`my-3 flex h-10 items-center justify-start overflow-hidden  rounded-md border border-primary shadow-lg shadow-fundo lg:my-0`,
				props.className,
				props.width ?? 'w-auto sm:w-[400px]',
			)}
		>
			{props.showFilter && props.filter}
			<div className="flex h-full w-full items-center justify-between font-medium">
				<input
					type="text"
					placeholder={`${props.placeholder ?? 'Pesquisar...'}`}
					value={props.value}
					onChange={props.onChange}
					className={`mx-2 h-full w-full text-white outline-none ${props.classNameInput ?? 'bg-fundo'}`}
				/>
				<LucideSearch size={20} className="mr-2 cursor-pointer" color="white" onClick={props.onClick} />
			</div>
		</div>
	);
}
