import { HeaderPermission } from '@/components/pages/admin/permissions/Header';
import { LoadingCircle } from '@/pages/Loading';
import { useContext, useState, useTransition } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { SwalAlert, SwalConfirmed } from '../../../../components/Swal';
import { StructurePageAdmin } from '../../../../components/pages/StructurePageAdmin';
import { TYPECOPY } from '../../../../constants/pages/permission/copyContext';
import { IdContextPermission } from '../../../../contexts/pages/Permissions/idCopyContext';
import { dataGet } from '../../../../hooks/axios';
import { api } from '../../../../services/Api';
import { PermissionType } from '../../../../types/TypeUser';
import { CardPermissionListener } from '../components/Card';

export function AllUsers() {
	const [search, setSearch] = useState<string>('');
	const { data: users } = dataGet<PermissionType[]>('/pessoa');
	const { userCopy } = useContext(IdContextPermission);
	const navigate = useNavigate();

	const [isPending, startTransition] = useTransition();

	function ChangeSearch(e: React.ChangeEvent<HTMLInputElement>) {
		startTransition(() => {
			setSearch(e.target.value);
		});
	}

	let usersFiltred =
		search.length > 0 && users ? users.filter((user) => user.nome.toUpperCase().includes(search.toUpperCase())) : users != undefined ? users : [];

	function VerifyTypeCopy(id: number, name: string) {
		if (userCopy?.type == TYPECOPY.COPY_FOR) {
			return [
				{ Nome: userCopy.nome, id: userCopy.id },
				{ Nome: name, id },
			];
		} else {
			return [
				{ Nome: name, id },
				{ Nome: userCopy?.nome, id: userCopy?.id },
			];
		}
	}

	function CopyPermission(id: number, name: string) {
		const dateForPost = VerifyTypeCopy(id, name);
		SwalAlert({
			title: `Copiar permissões de ${dateForPost[0].Nome}`,
			text: `para ${dateForPost[1].Nome}`,
		}).then((res) => {
			if (res.isConfirmed) {
				api.post(`/pessoa/darPermissaoDe/${dateForPost[0].id}/para/${dateForPost[1].id}`).then(() => {
					SwalConfirmed({ title: 'Registrado com sucesso' })
						.then(async () => {
							if (userCopy?.type === TYPECOPY.COPY_TO) {
								navigate(-1);
								return;
							}
							SwalAlert({ title: 'Continuar copiando?' }).then((swalResponse) => (!swalResponse.isConfirmed ? navigate(-1) : null));
						})
						.catch(() =>
							toast.error('Falha ao copiar', {
								position: 'top-right',
							}),
						);
				});
			}
		});
	}

	return (
		<StructurePageAdmin>
			<HeaderPermission title="Usuários" value={search} onChange={ChangeSearch} />
			{
				<div className="mb-4 space-y-4">
					{isPending && <LoadingCircle />}
					{usersFiltred?.map((user) => (
						<CardPermissionListener key={user.id} nome={user.nome} isCopy={true} onClickCopy={() => CopyPermission(user.id, user.nome)} />
					))}
				</div>
			}
		</StructurePageAdmin>
	);
}
