import { PERMISSAO } from '@/utils/constants/permissions';
// import { IPermissions } from './permissions';

export interface PropsGroups {
	id: number;
	namePermission: string;
	group: string;
}

export interface IPermissions {
	id: number;
	name: string;
	open: boolean;
	permissions: Permission[];
}

export interface Permission {
	name: string;
	value: number;
	checked: boolean;
}

const permission: PropsGroups[] = Object.keys(PERMISSAO).map((key) => ({
	id: PERMISSAO[key as keyof typeof PERMISSAO],
	namePermission: key,
	group: checkBelongs(PERMISSAO[key as keyof typeof PERMISSAO]),
}));

function checkBelongs(idPermission: number) {
	let permissionsLive = [PERMISSAO.LIVE, PERMISSAO.CADASTRAR_LIVE, PERMISSAO.VER_ENVIOS_LIVE, PERMISSAO.LANCAMENTO_MANUAL_LIVE];
	let permissionsLivro = [PERMISSAO.RESUMO_DO_LIVRO, PERMISSAO.VER_ENVIOS_RESUMO_LIVRO];
	let permissionsTVMarins = [PERMISSAO.TV_MARINS, PERMISSAO.VER_ENVIOS_TV_MARINS];
	let permissionsQuiosque = [PERMISSAO.GERENCIAR_QUIOSQUE, PERMISSAO.COMPROVAR_PRESENCA_QUIOSQUE];
	let permissionsPonto = [PERMISSAO.PONTOS, PERMISSAO.GERENCIAR_PONTOS];
	let permissionsPlataformaMarins = [
		PERMISSAO.PLATAFORMA_MARINS,
		PERMISSAO.DELETAR_PLATAFORMA_MARINS,
		PERMISSAO.GERENCIAR_PLATAFORMA_MARINS,
		PERMISSAO.VER_ENVIOS_PLATAFORMA_MARINS,
	];
	let permissionsErros = [PERMISSAO.ERROS, PERMISSAO.VER_ENVIOS_ERROS];
	let permissionsCoisaBoa = [PERMISSAO.COISABOA, PERMISSAO.VER_ENVIOS_COISA_BOA];
	let permissionsImpossivel = [PERMISSAO.IMPOSSIVEL, PERMISSAO.VER_ENVIOS_IMPOSSIVEL];
	let permissionsQuaseAcidente = [PERMISSAO.QUASE_ACIDENTE, PERMISSAO.VER_ENVIOS_QUASE_ACIDENTE];
	let permissionConvenio = [PERMISSAO.CONVENIOS];
	let permissionFinanceiro = [PERMISSAO.FINANCEIRO];
	let permissionSorteio = [PERMISSAO.SORTEIO];
	let permissionFrase = [PERMISSAO.FRASE_DA_SEMANA, PERMISSAO.CADASTRAR_FRASE_DA_SEMANA, PERMISSAO.VER_ENVIOS_FRASE_DA_SEMANA];
	let permissionVideo = [PERMISSAO.VIDEO_DA_SEMANA, PERMISSAO.CADASTRAR_VIDEO_DA_SEMANA, PERMISSAO.VER_ENVIOS_VIDEO_DA_SEMANA];
	let permissionLancamento = [PERMISSAO.LANCAMENTO, PERMISSAO.LANCAMENTO_MANUAL_LIVE, PERMISSAO.GERENCIAR_LANCAMENTO];
	let permissionLider = [PERMISSAO.LIDER, PERMISSAO.GERENCIAR_GESTORES, PERMISSAO.GERENCIAR_PESSOAS, PERMISSAO.CADASTRAR_SETOR];
	let permissionAprovarOuRejeitar = [PERMISSAO.APROVAR_OU_REJEITAR_ATIVIDADE];
	let permissionGerenciarLinks = [PERMISSAO.GERENCIAR_LINKS_PORMADE];
	let permissionGerenciarPermissoes = [PERMISSAO.GERENCIAR_PERMISSOES];
	let permissionGerenciarCafes = [PERMISSAO.GERENCIAR_CAFE_DIRECAO, PERMISSAO.CAFE_DIRECAO];

	if (permissionsLive.includes(idPermission)) {
		return 'Live';
	} else if (permissionsLivro.includes(idPermission)) {
		return 'Livro';
	} else if (permissionsTVMarins.includes(idPermission)) {
		return 'TV Marins';
	} else if (permissionsQuiosque.includes(idPermission)) {
		return 'Quiosque';
	} else if (permissionsPonto.includes(idPermission)) {
		return 'Pontos';
	} else if (permissionsPlataformaMarins.includes(idPermission)) {
		return 'Plataforma Marins';
	} else if (permissionsErros.includes(idPermission)) {
		return 'Erros';
	} else if (permissionsCoisaBoa.includes(idPermission)) {
		return 'Coisa Boa';
	} else if (permissionsImpossivel.includes(idPermission)) {
		return 'Impossivel';
	} else if (permissionsQuaseAcidente.includes(idPermission)) {
		return 'QuaseAcidente';
	} else if (permissionConvenio.includes(idPermission)) {
		return 'Convênios';
	} else if (permissionFinanceiro.includes(idPermission)) {
		return 'Financeiro';
	} else if (permissionSorteio.includes(idPermission)) {
		return 'Sorteio';
	} else if (permissionFrase.includes(idPermission)) {
		return 'Frase da Semana';
	} else if (permissionVideo.includes(idPermission)) {
		return 'Vídeo da Semana';
	} else if (permissionLancamento.includes(idPermission)) {
		return 'Lançamento';
	} else if (permissionLider.includes(idPermission)) {
		return 'Líderes';
	} else if (permissionAprovarOuRejeitar.includes(idPermission)) {
		return 'Gerenciar Atividade';
	} else if (permissionGerenciarLinks.includes(idPermission)) {
		return 'Links';
	} else if (permissionGerenciarPermissoes.includes(idPermission)) {
		return 'Gerenciar Permissões';
	} else if (permissionGerenciarCafes.includes(idPermission)) {
		return 'Cafés com a Direção';
	} else {
		return 'Outros';
	}
}

const Permission: IPermissions[] = Array.from(new Set(permission.map((item) => item.group))) // Obter grupos únicos
	.map((group, index) => {
		return {
			id: index,
			name: group,
			open: true,
			permissions: permission
				.filter((item) => item.group === group)
				.map((item) => ({
					name: item.namePermission.replace(/_/g, ' '),
					value: item.id,
					checked: false,
				})),
		};
	});

export { Permission };
