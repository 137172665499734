import pormade from '@/assets/image/logo-pormade-branco.png';
import { dropIn } from '@/utils/constants/framer/animations';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { TbHomeEco } from 'react-icons/tb';
import './modal.css';

export default function ModalHorarioTrabalho({ close }: any) {
	useHotkeys('esc', () => close());

	const random = (min: number, max: number) => Math.random() * (max - min) + min;

	const randomColors = ['#03FC73', '#3BBD75', '#01B14F', '#4EFC9D', '#017D39'];

	const createBubbles = async () => {
		const ul: HTMLElement | null = document.getElementById('circles');
		for (let i = 0; i < 10; i++) {
			const li = document.createElement('li');

			const size = Math.floor(random(50, 120));
			const position = random(1, 94);
			const delay = random(5, 1);
			const duration = random(10, 40);

			li.style.width = `${size}px`;
			li.style.height = `${size}px`;

			li.style.backgroundColor = randomColors[Math.floor(random(0, 7))];

			li.style.left = `${position}%`;

			li.style.animationDelay = `${delay}s`;
			li.style.animationDuration = `${duration}s`;

			li.style.animationTimingFunction = `cubic-bezier(${Math.random()}, ${Math.random()}, ${Math.random()}, ${Math.random()})`;

			ul?.appendChild(li);
		}
	};

	useEffect(() => {
		const callbubbles = async () => {
			await createBubbles();
		};
		callbubbles();
	});

	return (
		<div className="modal" onClick={close}>
			<motion.div
				onClick={(e: any) => e.stopPropagation()}
				variants={dropIn}
				initial="hidden"
				animate="visible"
				exit="exit"
				transition={{ duration: 0.6 }}
				className="ContainerSorteio relative z-50 mx-auto flex h-[500px] w-[90%] flex-col rounded-lg p-8 text-white sm:w-[55%]"
			>
				<div className="z-50 mt-6 flex flex-col items-center justify-center text-xl xsm:text-4xl sm:flex-row sm:gap-3">
					<h1>Atenção Colaborador</h1>
				</div>
				<div className="relative z-50 flex h-full flex-col items-center justify-center">
					<p className="text-center text-base font-normal xsm:text-lg sm:text-xl ">
						Os Petims que demandam tempo para a realização estarão indisponíveis durante o horário de trabalho.
					</p>
					<p className="absolute bottom-0 my-4 flex w-full justify-end text-base xsm:text-lg">- Equipe RH</p>
				</div>

				<div className="z-50 flex flex-col items-center gap-3">
					<button className="button" type="button" onClick={close}>
						<span className="button__text">Voltar</span>
						<span className="button__icon">
							<TbHomeEco size={22} />
						</span>
					</button>
					<div className="flex w-full flex-col items-center">
						<img src={pormade} width={250} alt="" />
					</div>
				</div>
			</motion.div>
			<ul id="circles" className="circles2"></ul>
		</div>
	);
}
