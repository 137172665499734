import { MotionProps, motion } from 'framer-motion';
import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

const animations = {
	initial: { opacity: 0, x: -750 },
	animate: { opacity: 1, x: 0 },
	exit: { opacity: 0, x: 500 },
};

type AnimationPageProps = MotionProps & HTMLAttributes<HTMLDivElement>;

const AnimationPage = ({ children, className }: AnimationPageProps) => {
	return (
		<motion.div className={twMerge('', className)} variants={animations} initial="initial" animate="animate" exit="exit" transition={{ duration: 0.7 }}>
			{children}
		</motion.div>
	);
};

export default AnimationPage;
