import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@/components/componentsCN/ui/alert-dialog';
import { Button } from '@/components/componentsCN/ui/button';
import { FC, useCallback, useMemo, useState } from 'react';
import { ConfirmContext } from './confirm-context';
import type { ConfirmDialogProps, ConfirmDialogProviderProps, ConfirmOptions } from './types';

const ConfirmDialog: FC<ConfirmDialogProps> = ({
	isOpen,
	onOpenChange,
	config: {
		title,
		description,
		cancelButton,
		confirmButton,
		confirmText = 'Confirmar',
		cancelText = 'Cancelar',
		icon,
		customActions,
		alertDialog,
		alertDialogContent,
		alertDialogHeader,
		alertDialogTitle,
		alertDialogDescription,
		alertDialogFooter,
	},
	onConfirm,
	onCancel,
}) => {
	return (
		<AlertDialog open={isOpen} onOpenChange={onOpenChange} {...alertDialog}>
			<AlertDialogContent {...alertDialogContent} className="border-none">
				<AlertDialogHeader {...alertDialogHeader}>
					<AlertDialogTitle {...alertDialogTitle} className="text-xl font-medium">
						{icon && icon}
						{title}
					</AlertDialogTitle>

					<AlertDialogDescription {...alertDialogDescription} className="text-base font-normal">
						{description && description}
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter {...alertDialogFooter}>
					{customActions ? (
						customActions(onConfirm, onCancel)
					) : (
						<>
							<AlertDialogCancel asChild>
								<Button onClick={onCancel} {...cancelButton}>
									{cancelText}
								</Button>
							</AlertDialogCancel>
							<AlertDialogAction asChild>
								<Button onClick={onConfirm} {...confirmButton}>
									{confirmText}
								</Button>
							</AlertDialogAction>
						</>
					)}
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
};

export const ConfirmDialogProvider: FC<ConfirmDialogProviderProps> = ({ defaultOptions = {}, children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [options, setOptions] = useState<Partial<ConfirmOptions>>(defaultOptions);
	const [resolver, setResolver] = useState<(value: boolean) => void>(() => {});

	const confirm = useCallback(
		(options: ConfirmOptions) => {
			setOptions({ ...defaultOptions, ...options });
			setIsOpen(true);
			return new Promise<boolean>((resolve) => {
				setResolver(() => resolve);
			});
		},
		[defaultOptions],
	);

	const handleConfirm = useCallback(() => {
		setIsOpen(false);
		resolver(true);
	}, [resolver]);

	const handleCancel = useCallback(() => {
		setIsOpen(false);
		resolver(false);
	}, [resolver]);

	const contextValue = useMemo(() => ({ confirm }), [confirm]);

	return (
		<ConfirmContext.Provider value={contextValue}>
			{children}

			<ConfirmDialog isOpen={isOpen} onOpenChange={setIsOpen} config={options} onConfirm={handleConfirm} onCancel={handleCancel} />
		</ConfirmContext.Provider>
	);
};
