import { Suspense, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';
import { NotPermission } from '../pages/Admin/NotPermission/NotPermission';
import LoadingPage from '../pages/Loading';

export const VerifyPermissionByRoute = (props: any) => {
	const { user, loading } = useContext(AuthContext);

	if (loading) return <LoadingPage />;

	return user && user?.permissao.includes(props.permission) ? (
		<>
			<Suspense fallback={<LoadingPage />}>
				<Outlet />
			</Suspense>
		</>
	) : (
		<NotPermission />
	);
};
