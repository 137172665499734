import { Outlet } from 'react-router-dom';
import { IdContextPermissionProvider } from '../contexts/pages/Permissions/idCopyContext';

export function CopyPermissionProvider() {
	return (
		<IdContextPermissionProvider>
			<Outlet />
		</IdContextPermissionProvider>
	);
}
