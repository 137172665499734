import ModalHorarioTrabalho from '@/components/Modal/modalHorarioTrabalho';
import { dataGet } from '@/hooks/axios.ts';
import { AnimatePresence } from 'framer-motion';
import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';
import LoadingPage from '../pages/Loading';
const Dashboard = lazy(() => import('@/pages/Dashboard'));

export const VerifyWorkJourney = () => {
	const { user, loading } = useContext(AuthContext);

	const { data: horarioTrabalho } = dataGet('pessoa/horario-trabalho');

	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		if (horarioTrabalho) return setShowModal(true);
		return setShowModal(false);
	}, [horarioTrabalho]);

	const navigate = useNavigate();

	async function toggleModal() {
		setShowModal(false);
		navigate('/');
	}

	if (loading) return <LoadingPage />;

	return user && !showModal && !horarioTrabalho ? (
		<>
			<Suspense fallback={<LoadingPage />}>
				<Outlet />
			</Suspense>
		</>
	) : (
		<>
			<Dashboard />
			<AnimatePresence mode="wait" initial={false} onExitComplete={() => null}>
				{showModal && <ModalHorarioTrabalho conteudo={user} close={toggleModal} />}
			</AnimatePresence>
		</>
	);
};
