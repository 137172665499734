import { Suspense, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';
import { NotPermission } from '../pages/Admin/NotPermission/NotPermission';
import LoadingPage from '../pages/Loading';

export const AdminRoutes = () => {
	const { user, loading } = useContext(AuthContext);
	if (loading) return <LoadingPage />;

	return user && user.permissao.length !== 0 ? (
		<>
			<Suspense fallback={<LoadingPage />}>
				<Outlet />
			</Suspense>
		</>
	) : (
		<NotPermission />
	);
};
