import { PETIM } from '@/utils/constants/petim';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { IswalUltimos } from '../types/Type';

interface Swal {
	title: string;
	text?: string;
	textConfirmedButton?: string;
	textCancelButton?: string;
	textErrorButton?: string;
	colorCancelButton?: string;
	colorConfirmedButton?: string;
	timer?: number;
	icon?: SweetAlertIcon | undefined;
	confirmButton?: boolean | undefined;
	showCancelButton?: boolean | undefined;
	cancelButton?: boolean | undefined;
	showDenyButton?: boolean | undefined;
	denyButtonText?: string;
	denyButtonColor?: string;
}

export function SwalAlert(props: Swal) {
	return Swal.fire({
		background: '#222',
		customClass: {
			popup: 'shadow-2xl shadow-orange text-white',
			title: 'text-white',
			htmlContainer: 'text-white/90',
		},
		title: props.title ?? 'Alert',
		text: props.text,
		icon: 'warning',
		iconColor: 'orange',
		showConfirmButton: true,
		confirmButtonText: props.textConfirmedButton ?? 'Ok',
		confirmButtonColor: props.colorConfirmedButton ?? '#509d45',
		showDenyButton: props.showDenyButton ?? false,
		denyButtonText: props.denyButtonText,
		denyButtonColor: props.denyButtonColor ?? '#2874ae',
		showCancelButton: props.showCancelButton ?? false,
		cancelButtonText: props.textCancelButton ?? 'Cancelar',
		cancelButtonColor: props.colorCancelButton ?? '#DA4646',
	});
}

export function SwalConfirmed(props: Swal) {
	return Swal.fire({
		background: '#292929',
		customClass: {
			popup: 'shadow-2xl shadow-green/80 text-white',
			title: 'text-white',
		},
		title: props.title,
		text: props.text,
		icon: 'success',
		iconColor: '#509d45',
		showConfirmButton: true,
		confirmButtonText: props.textConfirmedButton ?? 'Fechar',
		confirmButtonColor: '#509d45',
		color: 'white',
	});
}

export function SwalError(props: Swal) {
	return Swal.fire({
		background: '#292929',
		customClass: {
			popup: 'shadow-2xl shadow-red/80 text-white',
			title: 'text-white',
		},
		title: props.title ?? 'Verifique os dados enviados',
		text: props.text,
		icon: 'error',
		confirmButtonColor: '#DA4646',
		confirmButtonText: props.textErrorButton ?? '<span>Fechar</span>',
		color: 'white',
	});
}

export function SwalConfirmedPost(props: Swal) {
	return Swal.fire({
		background: `#292929`,
		customClass: {
			popup: 'shadow-2xl shadow-green/80 text-white',
			title: 'text-white',
		},
		title: props.title,
		text: props.text,
		icon: 'success',
		confirmButtonColor: `#509d45`,
		cancelButtonColor: `#DA4646`,
		confirmButtonText: `${props.textConfirmedButton ? props.textConfirmedButton : '<a href="/">Voltar ao Menu</a>'}`,
		cancelButtonText: '<span>Fechar</span>',
		showCancelButton: true,
		color: 'white',
	}).then(async (response) => {
		if (response.isConfirmed) {
			window.location.replace('/');
		}
	});
}

export const Toast = Swal.mixin({
	background: '#white',
	color: '#222',
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 1900,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer);
		toast.addEventListener('mouseleave', Swal.resumeTimer);
	},
});

export function SwalTimer(props: Swal) {
	return Swal.fire({
		background: '#292929',
		customClass: {
			popup: 'shadow-2xl shadow-green/80 text-white ',
			title: 'text-white',
		},
		confirmButtonColor: `#509d45`,
		confirmButtonText: props.textConfirmedButton,
		cancelButtonColor: `#DA4646`,
		cancelButtonText: props.textCancelButton,
		title: props.title,
		text: props.text,
		icon: props.icon,
		showConfirmButton: props.confirmButton,
		showCancelButton: props.cancelButton,
		timer: props.timer ? props.timer : 3000,
		color: 'white',
		timerProgressBar: true,
	});
}

export function SwalConvenio(props: Swal) {
	return Swal.fire({
		background: '#292929',
		customClass: {
			popup: 'shadow-2xl shadow-green/80 text-white',
			title: 'text-white',
		},
		title: props.title,
		text: props.text,
		icon: 'question',
		iconColor: 'white',
		showConfirmButton: true,
		confirmButtonText: props.textConfirmedButton ?? 'Sim',
		confirmButtonColor: '#509d45',
		showCancelButton: true,
		cancelButtonText: props.textCancelButton ?? 'Não',
		cancelButtonColor: '#DA4646',
		color: 'white',
	});
}

export function SwalUltimos(item: IswalUltimos) {
	function booleanToVerdadeiroFalso(houveAcidente?: boolean) {
		if (houveAcidente == true) {
			return 'Sim';
		} else if (houveAcidente == false) {
			return 'Não';
		}
	}

	if (item.tipoId == PETIM.QuaseAcidente) {
		Swal.fire({
			title: item.titulo,
			html: `
            <strong>Houve Acidente: </strong>${booleanToVerdadeiroFalso(item.houveAcidente)}
            <br>
            <strong>Local ou Máquina: </strong>${item.localOuMaquina}
            <br>
            <strong>Como Ocorreu: </strong>${item.comoOcorreu}
            <br>
            <strong>Como Resolver: </strong>${item.comoResolver}
            `,
			confirmButtonColor: `#509d45`,
			background: `#292929`,
			confirmButtonText: 'Fechar',
			color: 'white',
			customClass: {
				htmlContainer: 'text-left',
			},
		});
	} else if (item.tipoId == PETIM.Erros) {
		Swal.fire({
			title: 'Envio Anterior',
			html: `
            <strong>Erro:</strong>
            ${item.erro}
            <br/>
            <strong>Solução:</strong>
            ${item.solucao}
            `,
			confirmButtonColor: `#509d45`,
			background: `#292929`,
			confirmButtonText: 'Fechar',
			color: 'white',
			customClass: {
				htmlContainer: 'text-justify whitespace-pre-line',
			},
		});
	} else if (item.tipoId == PETIM.TvMarins) {
		Swal.fire({
			title: 'Envio Anterior',
			html: `
            <strong>Título:</strong>
            ${item.titulo}
            <br/>
            <strong>Texto:</strong>
            ${item.texto}
            `,
			confirmButtonColor: `#509d45`,
			background: `#292929`,
			confirmButtonText: 'Fechar',
			color: 'white',
			customClass: {
				htmlContainer: 'h-[300px] sm:h-[450px] overflow-auto text-justify whitespace-pre-line',
			},
		});
	} else if (item.tipoId == PETIM.CoisaBoa || item.tipoId == PETIM.Impossivel) {
		Swal.fire({
			title: 'Envio Anterior',
			html: `${item.texto}`,
			confirmButtonColor: `#509d45`,
			background: `#292929`,
			confirmButtonText: 'Fechar',
			color: 'white',
			customClass: {
				htmlContainer: 'text-justify whitespace-pre-line',
			},
		});
	}
}
