import { createContext, useState } from 'react';

export interface userCopy {
	id: number | null;
	nome: string;
	type: number;
}

interface userCopyProps {
	userCopy: userCopy | null;
	setUserContext(id: userCopy | null): void;
}

export const IdContextPermission = createContext({} as userCopyProps);

export function IdContextPermissionProvider({ children }: any) {
	const [userCopy, setUserCopy] = useState<userCopy | null>(null);

	function setUserContext(User: userCopy | null) {
		setUserCopy(User);
	}

	return <IdContextPermission.Provider value={{ userCopy, setUserContext }}>{children}</IdContextPermission.Provider>;
}
