import { StatusConnectionProvider } from '@/contexts/pages/StatusConnection/StatusConnection';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'sonner';
import { ConfirmDialogProvider } from './components/componentsCN/confirm-dialog/confirm-dialog';
import { Button } from './components/componentsCN/ui/button';
import { ThemeProvider } from './components/componentsCN/ui/theme-provider';
import { AuthProvider } from './contexts/AuthContext';
import Rotas from './routes/';

const queryClient = new QueryClient();
function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<StatusConnectionProvider>
				<ConfirmDialogProvider
					defaultOptions={{
						customActions(onConfirm, onCancel) {
							return (
								<>
									<Button variant={'destructive'} onClick={onCancel}>
										Cancelar
									</Button>
									<Button onClick={onConfirm}>Confirmar</Button>
								</>
							);
						},
					}}
				>
					<ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
						<AuthProvider>
							<Rotas />
							<Toaster
								theme="dark"
								richColors
								toastOptions={{
									classNames: {
										title: 'font-medium',
										toast: 'group toast p-4 group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
									},
								}}
								pauseWhenPageIsHidden
							/>
						</AuthProvider>
					</ThemeProvider>
				</ConfirmDialogProvider>
			</StatusConnectionProvider>
		</QueryClientProvider>
	);
}

export default App;
